<template>
    <div class="apply">
        <van-nav-bar
                title="申请服务费"
                left-text="返回"
                left-arrow
                fixed
                @click-left="onClickLeft"
        />
        <div class="geren" v-if="co_mode==1">
            <ul>
                <li>
                    <div class="label">当前合作方式:</div>
                    <div class="val">个人</div>
                </li>
                <li>
                    <div class="label">月可申请额度:</div>
                    <div class="val">{{price}}（元）</div>
                </li>
                <li>
                    <div class="label">综合服务费率:</div>
                    <div class="val">7% <span>（1000元以下不收取）</span></div>
                </li>
                <li>
                    <div class="label">申请与发放:</div>
                    <div class="val">1-3个工作日内发放</div>
                </li>
            </ul>
        </div>
        <div class="sixTypes">
            <div class="title">
                请选择申请类型
            </div>
          <div class="onetype" style="padding-top: 20px">
            <div class="wrap" :class="onetype?'wrapHeight':''">
              <div class="inputWrap">
                <div class="label">申请金额</div>
                <div class="inputBoxMoney">
                  <span>￥</span><input type="number" v-model="typeFileList1.amount" @input="addMoney"
                                       placeholder="请填写金额">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="applyPrice" v-if="co_mode==1">
            <div class="cardPrice">
                <div class="applyBank">
                    <div class="label">申请到</div>
                    <div class="r" @click="bankShowPopup">
                        <span>{{bankInfo}}</span>
                        <van-icon name="arrow" color="rgba(119,118,118,1)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixedBot" v-if="showBtn">
            <div class="wrap">
                <div class="left">申请金额：<span>￥{{number}}</span></div>
                <van-button type="primary" size="small" color="#F15223" round @click="submitApply">确认提交</van-button>
            </div>
        </div>
        <van-popup v-model="bankShow" closeable close-icon-position="top-left" position="bottom" :style="{ height: '50%' }">
            <ul class="bankList">
                <li v-for="(v,i) in bankList" :key="i" @click="selectBank(v.account_bank,v.account_num,v.id,v.account_num_real)">
                    <span>{{v.account_bank}}( {{v.account_num}} )</span>
                    <van-icon name="success" color="rgba(241,82,35,1)" v-if="v.id==bankId"/>
                </li>
            </ul>
        </van-popup>
        <div style="height: 1.2rem"></div>
        <van-dialog v-model="submitPopupShow" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title">
                    <p class="name">申请金额</p>
                    <p class="price">￥{{number}}</p>
                </div>
                <ul>
                    <li>
                        <span>申请金额</span> <span>￥{{number}}</span>
                    </li>
                    <li>
                        <span>预计服务费（7%）</span> <span>￥{{ratePrice}}</span>
                    </li>
                    <li>
                        <span>预计实收金额</span> <span>￥{{realPrice}}</span>
                    </li>
                </ul>
                <div class="tip">预计1-3个工作日审核通过</div>
                <div class="submitBtn" @click="submitBtn">
                    确认提交
                </div>
            </div>
        </van-dialog>


        <!--社群管理弹框-->
        <van-dialog v-model="communityMode" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title" style="position: relative">
                    <p class="name" style="color:#F26950;">社群管理</p>
                    <div style="position: absolute;right: 0;top: 0;" @click="onBasis('communityMode')">
                        <van-icon name="cross" size="0.38rem" color="#c9c9c9"/>
                    </div>
                </div>
                <div class="mode-text">① 群主：群成员在100人以内 200 元，100-160人 400 元，160-200人 500 元，200-300人 1000 元，300-400人 2000
                    元，400-500人 3000 元；
                </div>
                <div class="mode-text">② 群管理员：200-500 元/月；</div>
                <div class="mode-text">③ 讲师：1000-10000 元/月（在微信社群讲解关于品牌或产品的相关课程）；</div>
                <div class="submitBtn2" @click="onBasis('communityMode')">我已了解</div>
            </div>
        </van-dialog>
        <!--推广宣传弹框-->
        <van-dialog v-model="extensionMode" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title" style="position: relative">
                    <p class="name" style="color:#F26950;">推广宣传</p>
                    <div style="position: absolute;right: 0;top: 0;" @click="onBasis('extensionMode')">
                        <van-icon name="cross" size="0.38rem" color="#c9c9c9"/>
                    </div>
                </div>
                <div class="mode-text">① 线上推广：（在微信社群、朋友圈等平台进行品牌推广和产品宣传的文案或视频）宣传次数不低于 20 次，申请费用不得超过 500 元；</div>
                <div class="mode-text">② 线下推广：（线下实体店铺等渠道宣传品牌及产品）一个宣传点不得超过 500 元</div>
                <div class="submitBtn2" @click="onBasis('extensionMode')">我已了解</div>
            </div>
        </van-dialog>
        <!--物料宣传-->
        <van-dialog v-model="materialMode" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title" style="position: relative">
                    <p class="name" style="color:#F26950;">物料宣传</p>
                    <div style="position: absolute;right: 0;top: 0;" @click="onBasis('materialMode')">
                        <van-icon name="cross" size="0.38rem" color="#c9c9c9"/>
                    </div>
                </div>
                <div class="mode-text">① 线上宣传物料：（指在朋友圈文案或海报文案等）申请费用不得超过 1000 元</div>
                <div class="mode-text">② 线下宣传物料：（指店内 KT 板、宣传单页、X 展架等用于品牌及产品推广宣传的各种物料）申请费用不得超过 1000 元</div>
                <div class="submitBtn2" @click="onBasis('materialMode')">我已了解</div>
            </div>
        </van-dialog>
        <!--会议服务-->
        <van-dialog v-model="meetingMode" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title" style="position: relative">
                    <p class="name" style="color:#F26950;">会议费</p>
                    <div style="position: absolute;right: 0;top: 0;" @click="onBasis('meetingMode')">
                        <van-icon name="cross" size="0.38rem" color="#c9c9c9"/>
                    </div>
                </div>
                <div class="mode-text">① 会议主办方：每人 100-150 元/场（需要提供会议时间、会议地点、会议内容、签到表及现场图片</div>
                <div class="mode-text">② 会务人员：15人以上 100 元/场，15-50人 100-200 元/场，50人以上 200-300 元/场（需提供在场图片）</div>
                <div class="mode-text">③ 主持人：50人以内 300 元/场，50-100人 300-1000 元/场，超过100人根据主持人经验另议（需提供现场主持图片）</div>
                <div class="mode-text">④ 讲师：500-5000 元/场（需提供讲课课件、视频及现场讲课图片）</div>
                <div class="submitBtn2" @click="onBasis('meetingMode')">我已了解</div>
            </div>
        </van-dialog>
        <!--售后服务-->
        <van-dialog v-model="salesMode" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title" style="position: relative">
                    <p class="name" style="color:#F26950;">售后服务</p>
                    <div style="position: absolute;right: 0;top: 0;" @click="onBasis('salesMode')">
                        <van-icon name="cross" size="0.38rem" color="#c9c9c9"/>
                    </div>
                </div>
                <div class="mode-text">需提交做售后服务的微信截图或图片等凭证，3000-5000 元/月</div>
                <div class="submitBtn2" @click="onBasis('salesMode')">我已了解</div>
            </div>
        </van-dialog>
        <!--自媒体推广-->
        <van-dialog v-model="mediaMode" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title" style="position: relative">
                    <p class="name" style="color:#F26950;">自媒体推广</p>
                    <div style="position: absolute;right: 0;top: 0;" @click="onBasis('mediaMode')">
                        <van-icon name="cross" size="0.38rem" color="#c9c9c9"/>
                    </div>
                </div>
                <div class="mode-text">主播：每场 300-20000 元/月，直播间观看人数越多、时间越长，可申请金额就越高，一小时最低 150 元起，（需提供线上直播照片，每场直播最少提交 2
                    张，包括：直播过程中的一张照片，直播结束后带有直播“时长”的一张照片）；
                </div>
                <div class="mode-text" style="color: #FF9AA2;">说明：主播在直播过程中需与平台产品共同出镜</div>

                <div class="submitBtn2" @click="onBasis('mediaMode')">我已了解</div>
            </div>
        </van-dialog>


        <van-action-sheet v-model="zbMode" title="">
            <van-picker title="选择年月日" :columns="currentTime"   @confirm="onDateConfirm" @change="onDateChange" @cancel="onDateCancel" :show-toolbar="true"/>

        </van-action-sheet>


    </div>
</template>

<script>
    import api from "@/api/api"
    export default {

        data() {
            return {
                clientHeight: document.documentElement.clientHeight,
                noBank: null,
                showBtn: true,  // 控制按钮盒子显示隐藏
                openid: "oftPL6mLE4pyfl5-SijSk9JM_KPM",
                login: localStorage.getItem('login_token'),/* login:"gKm5AUr3FaMG74EUSYbE9kvb6K72c5yNdVtRxvSjuXdHeXrGFQ", */
                realPrice: 0,
                ratePrice: 0,
                price: 0,
                user: "",
                bankInfo: "",
                bankId: 0,
                co_mode: 1,/* 合作方式 */
                bankList: [],
                showSale: false,
                submitPopupShow: false,/* 提交弹框 */
                number: 0,
                originNumberType: 0,
                originNumber: 0,/* 金额 */
                invoiceImg: require("@/img/apply/icon_xuanzhong.png"),
                invoiceImgDefault: require("@/img/apply/icon_weixuanzhong.png"),
                uploadInvoiceImg: require("@/img/apply/shangchuanzhizhifapiao.png"),
                uploadPdf: require("@/img/apply/shangchuanpdf.png"),
                openImg: require("@/img/apply/zhankai.png"),
                bankShow: false,/* 银行弹出框 */
                twoTypeNumber: "",
                twoTypeindex: 0,
                sevenTypeNumber: "",
                sevenTypeindex: 0,
                fiveTypeNumber: 0,
                tip: "",
                sixTypeNumber: "",
                invoice_type: 1,
                onetype: false,
                twotype: false,
                threetype: false,
                fourtype: false,
                fivetype: false,
                sixtype: false,
                seventype: false,
                fileList0: [],
                fileList1: [],
                fileList2: [],
                preview1: [],
                preview2: [],
                preview3: [],
                preview4: [],
                preview5: [],
                preview6: [],
                preview7: [],
                preview8: [],
                type6: ["lecturer", "host", "conference"],
                typeFileList1: {
                    type: 1,//推广宣传
                    remark: "",//备注
                    amount: "",//金额
                    data: {//图片
                        imgs: []
                    }
                },
                typeFileList3: {
                    type: 5,//售后服务
                    remark: "",//备注
                    amount: "0",//金额
                    data: {//图片
                        imgs: []
                    }
                },
                typeFileList4: {
                    type: 3,//售前服务
                    remark: "",
                    amount: "",
                    data: {
                        imgs: []
                    }
                },
                typeFileList2: {
                    type: 2,//社群管理
                    remark: "",
                    data: {
                        group_num: 0,
                        group_detail: []
                    }
                },
                typeFileList5: {
                    type: 4,//宣传物料
                    amount: "",
                    remark: "",
                    data: {
                        imgs: []
                    }
                },
                typeFileList6: {
                    type: 6,//会议服务
                    remark: "",
                    meet_type: "conference",
                    data: {
                        "group_num": 0,
                        "group_detail": []
                    }
                },
                typeFileList7: {
                    type: 7,//自媒体推广
                    data: {
                        "group_num": 0,
                        "group_detail": []
                    }
                },
                data: [],
                typeFileList3Price: [
                    {
                        text: "0", value: "0"
                    },
                    {
                        text: "3000", value: "3000"
                    },
                    {
                        text: "4000", value: "4000"
                    },
                    {
                        text: "5000", value: "5000"
                    },
                ],
                invoice_file: "", /* pdf文件 */
                invoice_img: "", /* 发票图片 */
                communityMode: false,//社群管理弹框
                extensionMode: false,//推广宣传弹框
                materialMode: false,//物料宣传弹框
                meetingMode: false,//会议服务弹框
                salesMode: false,//售后服务弹框
                mediaMode: false,//自媒体弹框
                zbMode:false,
                currentTime:'',
                typeFileList7Index:0,//选择typeFileList7Index的下标
                dateTime:1,//1==开始使劲按  2==结束时间
                dateArr:[],

            }
        },
        watch: {
            typeFileList6: function (val) {
                console.log(val)
            },
            typeFileList7: function (val) {
                console.log(val)
            }
        },
        //计算属性
        computed: {},
        methods: {
            zbTimeMode(i,type){
                console.log(i)
                this.dateTimes()
                this.zbMode=true;
                this.typeFileList7Index=i;
                this.dateTime=type;


            },
            //选择日期确认
            onDateConfirm(index){
                console.log(index)
                var i=this.typeFileList7Index;
                var type=this.dateTime;
                var year=index[0].substring(0,index[0].indexOf('年'));
                var month=index[1].substring(0,index[1].indexOf('月'));
                var day=index[2].substring(0,index[2].indexOf('日'));

                var time=year+'/'+month+'/'+day+' '+index[3]+':'+index[4]+':'+index[5];
                if(type==1){
                    this.typeFileList7.data.group_detail[i].start_time=time
                }else{
                    this.typeFileList7.data.group_detail[i].end_time=time
                }
                this.zbMode=false;
                for (var k=0;k<this.currentTime.length;k++) {
                    this.currentTime[k].defaultIndex=0
                }

            },
            onDateChange(value,index){
                console.log(value,index)
                var dateYear=index[0];
                var yearNum=dateYear.substring(0,dateYear.indexOf('年'))
                var dateMonth=index[1];
                var monthNum=dateMonth.substring(0,dateMonth.indexOf('月'))
                var  dayNum=new Date(yearNum,monthNum,0).getDate();
                console.log(yearNum,monthNum,dayNum);
                var day=[];
                for(var j=0;j<dayNum;j++){
                    day=day.concat((j+1)+'日')
                }
                this.currentTime[2].values=day;





            },
            //选择日期取消
            onDateCancel(){
                this.zbMode=false;
            },
            // 金额计算
            async addMoney() {
                var money2 = 0, money6 = 0, total, money3 = 0;
                // //社群管理
                // if (this.twoTypeNumber) {
                //     for (var i = 0; i < this.twoTypeNumber; i++) {
                //         if (this.typeFileList2.data.group_detail[i].role == "群主") {
                //             if (this.typeFileList2.data.group_detail[i].amount > 5000) {
                //                 this.typeFileList2.data.group_detail[i].amount = 5000
                //                 this.$toast("金额不得大于5000")
                //             }
                //         }
                //         if (this.typeFileList2.data.group_detail[i].role == "讲师") {
                //             if (this.typeFileList2.data.group_detail[i].amount > 10000) {
                //                 this.typeFileList2.data.group_detail[i].amount = 10000
                //                 this.$toast("金额不得大于10000")
                //             }
                //         }
                //         if (this.typeFileList2.data.group_detail[i].role == "群管理员") {
                //             if (this.typeFileList2.data.group_detail[i].amount > 1000) {
                //                 this.typeFileList2.data.group_detail[i].amount = 1000
                //                 this.$toast("金额不得大于1000")
                //             }
                //         }
                //         money2 += this.typeFileList2.data.group_detail[i].amount * 1
                //     }
                // }
                // //自媒体推广
                // if (this.sevenTypeNumber) {
                //     for (var k = 0; k < this.sevenTypeNumber; k++) {
                //         if (this.typeFileList7.data.group_detail[k].amount * 1 > 20000) {
                //             this.$toast("金额不得大于20000")
                //         }
                //         if (this.typeFileList7.data.group_detail[k].amount * 1>0&&this.typeFileList7.data.group_detail[k].amount * 1 < 300) {
                //             this.$toast("金额不得小于300")
                //         }
                //
                //
                //         money3 += this.typeFileList7.data.group_detail[k].amount * 1
                //
                //
                //     }
                //
                // }
                // //会议服务
                // if (this.sixTypeNumber) {
                //     for (var j = 0; j < this.sixTypeNumber; j++) {
                //         // 会务
                //         if (this.typeFileList6.meet_type == "conference") {
                //             if (this.typeFileList6.data.group_detail[j].amount > 800) {
                //                 this.typeFileList6.data.group_detail[j]["amount"] = 800
                //                 this.$set(this.typeFileList6.data.group_detail, j, this.typeFileList6.data.group_detail[j])
                //                 this.$toast("金额不得大于800")
                //             }
                //         }
                //         // 主持 host
                //         if (this.typeFileList6.meet_type == "host") {
                //             if (this.typeFileList6.data.group_detail[j].amount > 3000) {
                //                 this.typeFileList6.data.group_detail[j].amount = 3000
                //                 this.$set(this.typeFileList6.data.group_detail, j, this.typeFileList6.data.group_detail[j])
                //                 this.$toast("金额不得大于3000")
                //             }
                //         }
                //         // 讲师
                //         if (this.typeFileList6.meet_type == "lecturer") {
                //             if (this.typeFileList6.data.group_detail[j].amount > 10000) {
                //                 this.typeFileList6.data.group_detail[j].amount = 10000
                //                 this.$set(this.typeFileList6.data.group_detail, j, this.typeFileList6.data.group_detail[j])
                //                 this.$toast("金额不得大于10000")
                //             }
                //         }
                //         // 主办方
                //         if (this.typeFileList6.meet_type == "master") {
                //             if (this.typeFileList6.data.group_detail[j].amount > 10000) {
                //                 this.typeFileList6.data.group_detail[j].amount = 10000
                //                 this.$set(this.typeFileList6.data.group_detail, j, this.typeFileList6.data.group_detail[j])
                //                 this.$toast("金额不得大于10000")
                //             }
                //         }
                //         money6 += this.typeFileList6.data.group_detail[j].amount * 1
                //     }
                // }
                // //推广宣传
                // if (this.typeFileList1.amount * 1 > 1000) {
                //     this.typeFileList1.amount = 1000
                //     this.$toast("金额不得大于1000")
                // }
                // //售前服务
                // if (this.typeFileList4.amount * 1 > 6000) {
                //     this.typeFileList4.amount = 6000
                //     this.$toast("金额不得大于6000")
                // }
                // //宣传物料
                // if (this.typeFileList5.amount * 1 > 1000) {
                //     this.typeFileList5.amount = 1000
                //     this.$toast("金额不得大于1000")
                // }

                total = money2 + money6 + money3 + this.typeFileList1.amount * 1 + this.typeFileList3.amount * 1 + this.typeFileList4.amount * 1 + this.typeFileList5.amount * 1
                this.number = Math.abs(total.toFixed(0))
            },
            // 确认提交
            submitBtn() {
                var _this=this;
                // var data = JSON.stringify(_this.data)
                _this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0
                });

                api.apply.applyNew(_this.openid, _this.login, _this.number, _this.bankId).then(res => {
                    if (res.data.code == 0) {
                        _this.$toast.clear();
                        _this.$toast(res.data.msg);
                        _this.submitPopupShow = false;
                        _this.$router.push('/applyRecord')
                    } else {
                        _this.$toast.clear();
                        _this.$toast(res.data.msg)
                    }
                }).catch(() => {
                    _this.$toast.clear();
                    _this.$toast('请求超时');
                    setTimeout(function () {
                        _this.$router.push({
                            path:'/applyRecord'
                        })
                    },1500)
                    _this.submitPopupShow = false
                })

            },
            // 选择银行卡
            selectBank(v1, v2, v3, rel) {
                this.noBank = rel
                this.bankId = v3
                this.bankInfo = v1 + "(" + v2 + ")"
                this.bankShow = false
            },
            bankShowPopup() {
                if (this.co_mode != 1) {
                    return
                }
                this.bankShow = true
            },
            // 上传发票电子
            invoice1(file) {
                let data = new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file', file.file);
                api.my.uploadIMg(data).then(res => {
                    console.log(res)
                    if (res.data.uploaded) {
                        this.invoice_img = res.data.url
                        file.status = '';
                        file.message = '';
                    } else {
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast(req.data.msg)
                })
            },
            // 上传pdf格式
            invoice2(file) {
                let data = new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file', file.file);
                api.my.uploadIMg(data).then(res => {
                    if (res.data.uploaded) {
                        this.invoice_file = res.data.url
                        file.status = '';
                        file.message = '';
                    } else {
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req => {

                    this.$toast(req.data.msg)
                })
            },
            // 上传纸质发票
            invoice3(file) {
                let data = new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file', file.file);
                api.my.uploadIMg(data).then(res => {
                    console.log(res)
                    if (res.data.uploaded) {
                        this.invoice_img = res.data.url
                        file.status = '';
                        file.message = '';
                    } else {
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req => {
                    this.$toast(req.data.msg)
                })
            },
            // 社群管理次数
            twoTypeNum() {
                if (this.twoTypeNumber < 50) {
                    this.preview2 = []
                    this.typeFileList2.data.group_detail = [];
                    for (var i = 0; i < this.twoTypeNumber; i++) {
                        this.$set(this.typeFileList2.data.group_detail, i, {
                            name: "",
                            num: "",
                            imgs: [],
                            role: "群主",
                            amount: "",
                            remark: "",
                        })
                    }
                    this.addMoney()
                } else {
                    this.$toast("数量不能超过50")
                }

            },
            // 讲课次数
            twoTypeNum6() {
                if (this.sixTypeNumber < 50) {
                    this.preview6 = []
                    this.typeFileList6.data.group_detail = [];
                    for (var j = 0; j < this.sixTypeNumber; j++) {
                        this.typeFileList6.data.group_detail[j] = {
                            name: "",
                            num: "",
                            imgs: [],
                            sign_table: "",
                            content: "",
                            amount: "",
                            remark: "",
                            address: ""
                        }
                    }
                    this.addMoney()
                } else {
                    this.$toast("数量不能超过50")
                }
            },
            //自媒体直播次数
            sevenTypeNum() {
                if (this.sevenTypeNumber < 50) {
                    this.preview8 = []
                    this.typeFileList7.data.group_detail = [];
                    for (var i = 0; i < this.sevenTypeNumber; i++) {
                        this.typeFileList7.data.group_detail[i] = {
                            name: "管理场" + (i + 1),
                            zb_name: "",
                            zb_time: "",
                            start_time: "",
                            end_time: "",
                            num: '',
                            imgs: [],
                            amount: "",
                            remark: "",
                        }
                    }
                    console.log(this.typeFileList7)
                    this.addMoney()
                } else {
                    this.$toast("数量不能超过50")
                }

            },
            opentype(i) {
                switch (i) {
                    case 1:
                        this.onetype = !this.onetype;
                        break;
                    case 2:
                        this.twotype = !this.twotype;
                        break;
                    case 3:
                        this.threetype = !this.threetype;
                        break;
                    case 4:
                        this.fourtype = !this.fourtype;
                        break;
                    case 5:
                        this.fivetype = !this.fivetype;
                        break;
                    case 6:
                        this.sixtype = !this.sixtype;
                        break;
                    case 7:
                        this.seventype = !this.seventype;
                        break;
                }
            },
            modifyType(i) {
                this.invoice_type = i
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            // afterRead
            onetypeUpload(file) {
                file.status = 'uploading';
                file.message = '上传中...';
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                this.typeFileList1.data.imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList1.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            // 图片上传
            delete1(file, details) {
                this.typeFileList1.data.imgs.splice(details.index, 1)
                console.log(file, this.typeFileList1)
            },
            afterRead2(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList2.data.group_detail[i].imgs.push(res.data.url)
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                this.$toast(req.data.msg)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList2.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            this.$toast(req.data.msg)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                }
            },
            delete2(i) {
                return (file, details) => {
                    console.log(file,)
                    this.typeFileList2.data.group_detail[i].imgs.splice(details.index, 1)
                    return true
                }
            },
            afterRead3(file) {
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            if (res.data.uploaded) {
                                this.typeFileList3.data.imgs.push(res.data.url)
                                this.typeFileList3.amount = "3000"
                                this.addMoney()
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList3.data.imgs.push(res.data.url)
                            this.typeFileList3.amount = "3000"
                            this.addMoney()
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            delete3(file, details) {
                this.typeFileList3.data.imgs.splice(details.index, 1)
                console.log(file,)
                this.typeFileList3.amount = 0
                this.addMoney()
            },
            afterRead4(file) {
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                this.typeFileList4.data.imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList4.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            delete4(file, details) {
                this.typeFileList4.data.imgs.splice(details.index, 1)
                console.log(file,)
            },
            afterRead5(file) {
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                this.typeFileList5.data.imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList5.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            delete5(file, details) {
                this.typeFileList5.data.imgs.splice(details.index, 1)
                console.log(file,)
            },
            afterRead6(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList6.data.group_detail[i].imgs.push(res.data.url)
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList6.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                }
            },
            delete6(i) {
                return (file, details) => {
                    console.log(file)
                    this.typeFileList6.data.group_detail[i].imgs.splice(details.index, 1)
                    return true
                }
            },
            afterRead7(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList6.data.group_detail[i].sign_table = res.data.url
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList6.data.group_detail[i].sign_table = res.data.url
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                }
            },
            delete7(i) {
                return (file) => {
                    console.log(file)
                    this.typeFileList6.data.group_detail[i].sign_table = ''
                    this.preview7[i] = {}
                    return true
                }
            },
            afterRead8(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList7.data.group_detail[i].imgs.push(res.data.url)
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                this.$toast(req.data.msg)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList7.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            this.$toast(req.data.msg)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                    console.log(this.preview8)
                    console.log(this.typeFileList7)
                }

            },
            delete8(i) {
                return (file, details) => {
                    console.log(file,)
                    this.typeFileList7.data.group_detail[i].imgs.splice(details.index, 1)
                    return true
                }
            },
            // 重置
            reset() {
                this.twoTypeNumber = 0, this.twoTypeindex = 0, this.fiveTypeNumber = 0, this.tip = "", this.sixTypeNumber = 0,
                    this.fileList0 = [], this.fileList1 = [], this.fileList2 = [], this.preview1 = [], this.preview2 = [], this.preview3 = [], this.preview4 = [], this.preview5 = [], this.preview6 = [], this.preview7 = [],
                    this.typeFileList1 = {
                        type: 1,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList3 = {
                        type: 5,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList4 = {
                        type: 3,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList2 = {
                        type: 2,
                        data: {
                            group_num: 0,
                            group_detail: []
                        }
                    },
                    this.typeFileList5 = {
                        type: 4,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList6 = {
                        type: 6,
                        remark: "",
                        data: {
                            "type": "conference",
                            "group_num": 0,
                            "group_detail": []
                        }
                    },
                    this.typeFileList7 = {
                        type: 7,//自媒体推广
                        data: {
                            "group_num": 0,
                            "group_detail": []
                        }
                    },
                    this.data = []
                this.invoice_file = "", /* pdf文件 */
                    this.invoice_img = "" /* 发票图片 */
                this.invoice_type = 1
            },
            // 提交申请
            submitApply() {
                this.data = []
                if (!this.bankId) {
                    this.$toast("请选择银行卡")
                    return
                }
                // var noBank=this.noBank;
                // if (noBank.substring(0, 10) == 6228480616) {
                //     this.$toast("不支持该银行卡")
                //     return
                // }
                if (parseInt(this.number) < 100) {
                    this.$toast("提现金额不能小于100")
                    return
                }
                console.log(this.data)
                if (this.co_mode == 1) {
                    this.getCost()
                } else {
                    this.submitBtn()
                }
            },
            getdata() {
                var that = this
                this.$toast({
                    message: '加载中...',
                    forbidClick: true,
                })
                api.apply.getdata(this.openid, this.login).then(res => {
                    console.log(res)
                    this.$toast.clear()
                    if (res.data.code == 0) {
                        if (!res.data.default_bank) this.$toast({message: "请先添加收款账户", duration: 3000});
                        that.bankInfo = res.data.default_bank?res.data.default_bank.account_bank + "(" + res.data.default_bank.account_num + ")":''
                        that.bankId = res.data.default_bank?res.data.default_bank.id:''
                        that.noBank = res.data.default_bank?res.data.default_bank.account_num_real:''
                        that.showSale = res.data.show_sale
                        if (that.co_mode == 1) {
                            that.bankList = res.data.bank_info
                            this.price = res.data.month_limit
                        }
                    } else {
                        this.$toast(res.data.msg)
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast.clear();
                    this.$toast('请求超时');
                    setTimeout(function () {
                        window.history.back()
                    },1500)

                })
            },
            getCost() {
                this.$toast({
                    message: '加载中...',
                    forbidClick: true,
                })
                api.apply.getCost(this.openid, this.login, this.number).then(res => {
                    this.$toast.clear()
                    if (res.data.code == 0) {
                        this.realPrice = res.data.resData.real_money
                        this.ratePrice = res.data.resData.fee
                        this.submitPopupShow = true
                    } else {
                        this.$toast(res.data.msg)
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast.clear()
                })
            },
            //说明弹框
            onBasis(mode) {
                if (mode == 'communityMode') {
                    //社群管理
                    this.communityMode = !this.communityMode
                } else if (mode == 'extensionMode') {
                    //推广宣传
                    this.extensionMode = !this.extensionMode
                } else if (mode == 'materialMode') {
                    //物料宣传
                    this.materialMode = !this.materialMode
                } else if (mode == 'meetingMode') {
                    //会议服务
                    this.meetingMode = !this.meetingMode
                } else if (mode == 'salesMode') {
                    //售后服务
                    this.salesMode = !this.salesMode
                } else if (mode == 'mediaMode') {
                    //自媒体推广
                    this.mediaMode = !this.mediaMode
                }
            },
            dateTimes() {
                var time=new Date();
                var  dayNum=new Date(time.getFullYear(),1,0).getDate();
                console.log(dayNum)
                var year=[time.getFullYear()+'年',(time.getFullYear()+1)+'年'];
                var month=['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];
                var day=[];
                for(var j=0;j<dayNum;j++){
                    day=day.concat((j+1)+'日')
                }
                var hour=["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
                var minute=["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];
                var second=["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];
                var currentTime=[
                    //年
                    {
                        values:year,
                        defaultIndex: 0,
                    },
                    //月
                    {
                        values:month,
                        defaultIndex: 0,
                    },
                    //日
                    {
                        values:day,
                        defaultIndex: 0,
                    },
                    //时
                    {
                        values:hour,
                        defaultIndex: 0,
                    },
                    //分
                    {
                        values:minute,
                        defaultIndex: 0,
                    },
                    //秒
                    {
                        values:second,
                        defaultIndex: 0,
                    },


                ];

                this.currentTime=currentTime;
            },


        },
        created() {
            this.openid = this.$store.state.openid
            this.co_mode = this.$route.query.co_mode
            this.getdata()
        },
        mounted() {
            window.onresize = () => {

                if (this.clientHeight > document.documentElement.clientHeight) {
                    this.showBtn = false

                } else {
                    this.showBtn = true

                }

            }
        },



    }



</script>

<style lang="less">
    .apply {
        width: 100%;
        padding-top: 1rem;

        .geren {
            width: 100%;
            padding: .34rem;
            background-color: #fff;
            box-sizing: border-box;
            border-top: .02rem solid rgba(229, 227, 227, 0.6);

            ul {
                li {
                    margin-bottom: .36rem;

                    .label {
                        display: inline-block;
                        width: 2.3rem;
                        text-align: right;
                        font-size: .32rem;
                        color: #B5B5B5;
                    }

                    .val {
                        display: inline-block;
                        text-align: right;
                        font-size: .32rem;
                        color: #0D0D0D;
                        padding-left: .42rem;

                        span {
                            font-size: .3rem;
                            color: rgba(241, 82, 35, 1);
                        }
                    }
                }
            }
        }

        .applyPrice {
            width: 100%;
            padding: .34rem 0;
            box-sizing: border-box;
            background-color: #f6f6f6;

            .cardPrice {
                width: 100%;
                // height: 4.3rem;
                border-radius: .2rem;
                background-color: #fff;

                .name {
                    line-height: .98rem;
                    text-align: center;
                    width: 100%;
                    font-size: .32rem;
                }

                .inputPrice {
                    width: 6.12rem;
                    height: 1.04rem;
                    border-radius: .1rem;
                    background-color: #fff8f8;
                    line-height: 1.04rem;
                    font-size: .56rem;
                    margin: 0 auto;

                    span {
                        width: 1.7rem;
                        display: inline-block;
                        padding-left: 1rem;
                        box-sizing: border-box;
                    }

                    input {
                        border: none;

                        width: 4.32rem;
                        height: 1.04rem;
                        border-radius: .1rem;
                        background-color: #fff8f8;
                    }
                }

                .tip {
                    width: 100%;
                    padding: .26rem 0 0 .58rem;
                    box-sizing: border-box;
                    font-size: .24rem;
                    height: .6rem;
                    color: #f12323;
                    margin-bottom: .42rem
                }

                .applyBank {
                    width: 100%;
                    box-sizing: border-box;
                    padding: .4rem;
                    height: 1.3rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .label {
                        font-size: .3rem;
                        color: rgba(119, 118, 118, 1);
                    }

                    .r {
                        width: 70%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }

        .invoiceWrap {
            width: 100%;
            padding: 0 .24rem;
            box-sizing: border-box;
            background-color: #f6f6f6;

            .invoiceTitle {
                display: flex;
                justify-content: space-between;
                line-height: .6rem;
                font-size: .28rem;
                color: #767575;
            }

            .invoiceBox {
                width: 100%;
                padding: .4rem .6rem;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: .2rem;

                .typeInvoice {
                    ul {
                        display: flex;
                        justify-content: space-between;

                        li {
                            width: 2rem;
                            height: .72rem;
                            border: .02rem solid #f15223;
                            line-height: .72rem;
                            box-sizing: border-box;
                            text-align: center;
                            border-radius: .1rem;

                            img {
                                width: .36rem;
                                height: .36rem;
                                margin-right: .1rem;
                                vertical-align: middle;
                            }

                            span {
                                font-size: .3rem;
                                color: #F15223;
                                vertical-align: middle;
                            }

                            .active {
                                color: #D6D6D6;
                            }
                        }

                        .active {
                            border-color: #D6D6D6;
                        }
                    }
                }

                .uploadImg {
                    width: 100%;
                    padding: .6rem 0;
                    height: 4.1rem;
                    box-sizing: border-box;

                    .uploadBox {
                        width: 2.5rem;
                        position: relative;
                        margin: 0 auto;

                        img {
                            width: 100%;
                        }

                        .van-uploader {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;

                            .van-uploader__upload {
                                width: 100%;

                                .van-uploader__preview {
                                    width: 100%;

                                    .van-uploader__preview-image {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .van-uploader__preview-image {
                            width: 2.5rem;
                        }

                        .imgHide {
                            opacity: 0;
                        }
                    }

                    .pdfBox {
                        width: 2.3rem;
                        height: 2.66rem;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                        }

                        p {
                            position: absolute;
                            bottom: -.8rem;
                            font-size: .22rem;
                            color: #FF6767;
                        }

                        .van-uploader {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;

                            .van-uploader__upload {
                                width: 100%;
                                height: 100%;

                            }

                            .van-uploader__file {
                                width: 2.3rem;
                                height: 2.66rem;
                            }
                        }

                        .imgHide {
                            opacity: 0;

                        }
                    }
                }
            }
        }

        .sixTypes {
            width: 100%;
            background-color: #f6f6f6;

            .title {
                padding: .2rem 0 0 .24rem;
                font-size: .28rem;
                color: #767575;
                line-height: .6rem;
            }

            .onetype {
                width: 100%;
                background-color: #fff;
                margin-bottom: .24rem;

                .wrap {
                    position: relative;
                    padding: 0 0 .86rem .3rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: rgba(74, 72, 72, 1);
                            padding-left: 0;
                        }
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    .uploadBox {
                        width: 100%;
                        padding-left: .3rem;
                        box-sizing: border-box;

                        .van-uploader__preview-image {
                            width: 1.76rem;
                        }
                    }

                    .inputWrap {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        line-height: .4rem;

                        .label {
                            font-size: .28rem;
                        }

                        .inputBoxMoney {
                            width: 4rem;
                            height: .8rem;
                            padding: .18rem .3rem;
                            background-color: rgba(255, 248, 248, 1);
                            box-sizing: border-box;
                            border: .02rem solid rgba(255, 228, 228, 1);
                            display: flex;
                            align-items: center;
                            font-size: .28rem;

                            input {
                                width: 2.5rem;
                                border: none;
                                background-color: rgba(255, 248, 248, 1);
                            }

                        }

                        .bornone {
                            border: none;
                            background: #fff;

                            input {
                                width: 2.5rem;
                                border: none;
                                background: transparent;
                            }
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 96%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 1.22rem;
                }
            }

            .twoType {
                width: 100%;
                background-color: #fff;
                margin-bottom: .24rem;

                .wrap {
                    position: relative;
                    padding-bottom: .86rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        padding: 0 0 0 .3rem;
                        box-sizing: border-box;

                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: rgba(74, 72, 72, 1);
                            padding-left: 0;
                        }

                        input {
                            border: none;
                            width: 2rem;
                        }

                        .line1 {
                            line-height: .6rem;
                            padding-bottom: .46rem;
                        }

                        .line2 {
                            font-size: .32rem;
                        }
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    ul {
                        border-top: .02rem solid #f0eeee;
                        padding: 0 .48rem;
                        width: 100%;
                        box-sizing: border-box;

                        li {
                            border-bottom: .02rem solid #f0eeee;
                            padding: .3rem 0;
                            box-sizing: border-box;

                            .name {
                                padding-left: .64rem;
                                color: rgba(99, 79, 198, 1);
                                font-size: .28rem;
                            }

                            .inputbox {
                                width: 100%;
                                position: relative;
                                padding-left: 1.14rem;
                                margin: .32rem 0 .28rem 0;
                                box-sizing: border-box;
                                font-size: .28rem;

                                .text {
                                    position: relative;
                                    width: 1.56rem;
                                    display: inline-block;
                                }

                                .text::after {
                                    position: absolute;
                                    content: "*";
                                    right: .51rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text1 {
                                    position: relative;
                                    width: 1.9rem;
                                    display: inline-block;
                                }

                                .text1::after {
                                    position: absolute;
                                    content: "*";
                                    right: -.05rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text5 {
                                    position: relative;
                                    width: 2rem;
                                    display: inline-block;
                                }

                                .text5::after {
                                    position: absolute;
                                    content: "*";
                                    right: .51rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text52 {
                                    position: relative;
                                    width: 2rem;
                                    display: inline-block;
                                }

                                .text52::after {
                                    position: absolute;
                                    content: "*";
                                    right: -.05rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                input {
                                    border: none;
                                    margin-left: 0.2rem;
                                    width: 3rem;
                                    font-size: .28rem;
                                }
                            }

                            .inputbox::after {
                                position: absolute;
                                content: "";
                                left: .76rem;
                                top: .16rem;
                                width: .1rem;
                                height: .1rem;
                                background-color: rgba(255, 134, 92, 1);
                                border-radius: 50%;
                            }

                            .uploadBox {
                                width: 100%;
                                padding-left: .3rem;
                                box-sizing: border-box;

                                .van-uploader__preview-image {
                                    width: 1.76rem;
                                }
                            }

                            .radioWrap {
                                padding: .3rem .5rem;
                                display: flex;
                                justify-content: space-between;

                                .activeIcon {
                                    display: inline-block;
                                    width: .32rem;
                                    height: .32rem;
                                    border-radius: 50%;
                                    border: .02rem solid rgba(133, 128, 128, 1);
                                    box-sizing: border-box;
                                }

                                .inactiveIcon {
                                    display: inline-block;
                                    position: relative;
                                    width: .32rem;
                                    height: .32rem;
                                    border-radius: 50%;
                                    box-sizing: border-box;
                                    background: rgba(241, 82, 35, 1);
                                }

                                .inactiveIcon::after {
                                    position: absolute;
                                    content: '';
                                    width: .28rem;
                                    height: .28rem;
                                    border-radius: 50%;
                                    box-sizing: border-box;
                                    background: rgba(241, 82, 35, 1);
                                    border: .04rem solid #fff;
                                    left: 0;
                                    right: 0;
                                    margin: 0 auto;
                                    top: .02rem;
                                }
                            }

                            .inputWrap {
                                width: 100%;
                                padding: .3rem;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                font-size: .28rem;

                                .inputBoxMoney {
                                    width: 4rem;
                                    height: .8rem;
                                    padding: .18rem .3rem;
                                    background-color: rgba(255, 248, 248, 1);
                                    box-sizing: border-box;
                                    border: .02rem solid rgba(255, 228, 228, 1);
                                    display: flex;
                                    align-items: center;
                                    font-size: .28rem;
                                    line-height: .17rem;

                                    input {
                                        width: 2.5rem;
                                        border: none;
                                        background-color: rgba(255, 248, 248, 1);
                                    }
                                }
                            }
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem .7rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 96%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 2.2rem;
                }
            }

            .threeType {
                width: 100%;
                background-color: #fff;
                margin-bottom: .24rem;

                .wrap {
                    position: relative;
                    padding-bottom: .86rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        padding: 0 0 0 .3rem;
                        box-sizing: border-box;

                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: #4A4848;
                            padding-left: 0;
                        }

                        input {
                            border: none;
                            width: 2rem;
                            font-size: .28rem;
                        }

                        .activeIcon {
                            display: inline-block;
                            width: .32rem;
                            height: .32rem;
                            border-radius: 50%;
                            border: .02rem solid rgba(133, 128, 128, 1);
                            box-sizing: border-box;
                        }

                        .inactiveIcon {
                            display: inline-block;
                            position: relative;
                            width: .32rem;
                            height: .32rem;
                            border-radius: 50%;
                            box-sizing: border-box;
                            background: rgba(241, 82, 35, 1);
                        }

                        .inactiveIcon::after {
                            position: absolute;
                            content: '';
                            width: .28rem;
                            height: .28rem;
                            border-radius: 50%;
                            box-sizing: border-box;
                            background: rgba(241, 82, 35, 1);
                            border: .04rem solid #fff;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            top: .02rem;
                        }

                        .radioWrap {
                            justify-content: space-around;
                        }

                        .van-radio__label {
                            width: auto;
                        }
                    }

                    .Overlay {
                        position: relative;
                        left: 0;
                        top: 0;
                    }

                    .Overlay:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #FEFEFE;
                        opacity: 0.62;
                        z-index: 10;
                        width: 100%;
                        height: 100%;
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;
                        z-index: 20;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    ul {
                        border-top: .02rem solid #f0eeee;
                        padding: 0 .48rem;
                        width: 100%;
                        box-sizing: border-box;

                        li {
                            border-bottom: .02rem solid #f0eeee;
                            padding: .3rem 0;
                            box-sizing: border-box;

                            .deleteBox {
                                width: 100%;
                                padding-right: .3rem;
                                box-sizing: border-box;

                                .text {
                                    width: 80%;
                                    color: #FA0202;
                                    font-size: .28rem;
                                }

                                .delBtn {
                                    display: inline-block;
                                    padding: .05rem .2rem;
                                    font-size: .28rem;
                                    color: #fff;
                                    background-color: #F15223;
                                    text-align: center;
                                    border-radius: .1rem;
                                }
                            }

                            .name {
                                padding-left: .3rem;
                                color: rgba(99, 79, 198, 1);
                                font-size: .28rem;
                            }

                            .inputbox {
                                width: 100%;
                                position: relative;
                                padding-left: .84rem;
                                margin: .32rem 0 .28rem 0;
                                box-sizing: border-box;
                                font-size: .28rem;

                                .text {
                                    position: relative;
                                    width: 1.56rem;
                                    display: inline-block;
                                }

                                .text::after {
                                    position: absolute;
                                    content: "*";
                                    right: 0rem;
                                    top: .12rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                input {
                                    border: none;
                                    margin-left: .68rem;
                                    width: 2.3rem;
                                    font-size: .28rem;
                                }
                            }

                            .inputbox::after {
                                position: absolute;
                                content: "";
                                left: .46rem;
                                top: .16rem;
                                width: .1rem;
                                height: .1rem;
                                background-color: rgba(255, 134, 92, 1);
                                border-radius: 50%;
                            }

                            .uploadBox {
                                width: 100%;
                                padding-left: .3rem;
                                box-sizing: border-box;

                                .van-uploader__preview-image {
                                    width: 1.76rem;
                                }

                                p {
                                    font-size: .3rem;
                                    line-height: .6rem;
                                    font-weight: bold;
                                }
                            }

                            .inputWrap {
                                width: 100%;
                                padding: .3rem;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                font-size: .28rem;

                                .inputBoxMoney {
                                    width: 4rem;
                                    height: .8rem;
                                    padding: .18rem .3rem;
                                    background-color: rgba(255, 248, 248, 1);
                                    box-sizing: border-box;
                                    border: .02rem solid rgba(255, 228, 228, 1);
                                    display: flex;
                                    align-items: center;
                                    font-size: .28rem;

                                    input {
                                        border: none;
                                        width: 2.5rem;
                                        background-color: rgba(255, 248, 248, 1);
                                    }
                                }
                            }
                        }

                        .Overlay {
                            position: relative;
                            left: 0;
                            top: 0;
                        }

                        .Overlay:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: #FEFEFE;
                            opacity: 0.62;
                            z-index: 10;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 100%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 3.9rem;
                }
            }

            .desc {
                font-size: .26rem;
                color: #C9C9C9;
                padding-left: .3rem;

                span {
                    color: rgba(241, 82, 35, 1);
                }
            }
        }

        .fixedBot {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1.2rem;
            display: flex;
            align-items: center;
            background: #fff;
            font-size: .32rem;
            padding: 0 0.52rem;
            box-sizing: border-box;
            border-top: .02rem solid rgba(220, 220, 220, 1);
            z-index: 30;

            .wrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    span {
                        color: rgba(241, 82, 35, 1);
                    }
                }
            }
        }

        .bankList {
            width: 100%;
            padding-top: 1rem;

            li {
                width: 100%;
                box-sizing: border-box;
                padding: 0 .8rem;
                line-height: 1.1rem;
                font-size: .32rem;
                color: #000;
                border-bottom: .02rem solid rgba(229, 227, 227, 0.6);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }

        .submitPopupBox {
            padding: 0 .3rem .3rem .3rem;
            box-sizing: border-box;

            .title {
                color: #000;
                text-align: center;

                .name {
                    font-size: .32rem;
                }

                .price {
                    font-size: .44rem;
                    margin: .3rem 0 .5rem 0;
                }
            }

            ul {
                li {
                    display: flex;
                    justify-content: space-between;
                    font-size: .28rem;
                    color: #000;
                    margin-bottom: .26rem;
                }
            }

            .tip {
                font-size: .22rem;
                color: rgba(253, 32, 32, 1);
                width: 100%;
                text-align: center;
                margin: .46rem 0 .7rem 0;
            }

            .submitBtn {
                width: 3.6rem;
                line-height: .6rem;
                background-color: rgba(241, 82, 35, 1);
                border-radius: .1rem;
                text-align: center;
                margin: 0 auto;
                color: #fff;
            }
        }

        .van-dropdown-menu__bar {
            background-color: transparent;
            box-shadow: none;

            .van-ellipsis {
                width: 2.5rem;
            }
        }

        .flex {
            display: flex;
            height: 0.7rem;
            line-height: 0.7rem;
            align-items: center;
            padding-left: .6rem;
            font-size: .3rem;
            color: #1989fa;
        }

        .mode-text {
            padding-top: 0.2rem;
            font-size: .3rem;
            color: #B2BAC9;
        }

        .submitBtn2 {
            width: 3.6rem;
            line-height: .6rem;
            background-color: rgba(241, 82, 35, 1);
            border-radius: 99999px;
            text-align: center;
            margin: 0 auto;
            margin-top: 0.2rem;
            color: #fff;
        }


    }
</style>